import 'react-toastify/dist/ReactToastify.css';

import React, {createContext, useContext} from 'react';
import {ToastContainer} from 'react-toastify';

import styles from './ToastContext.module.scss';

export const ToastContext = createContext<any>(undefined);

export const useToast = () => useContext(ToastContext);

export function ToastProvider(props: any) {
  return (
    <ToastContext.Provider value={{}}>
      {props.children}
      <ToastContainer
        limit={8}
        position="bottom-right"
        closeOnClick={false}
        toastClassName={styles.toast}
      />
    </ToastContext.Provider>
  );
}
