import {useMemo} from 'react';
import {useSelector} from 'react-redux';

import {getAccessToken} from '../services/reducers/authSlice';

export const useAuth = () => {
  const isAuth = useSelector(getAccessToken);

  return useMemo(() => isAuth, [isAuth]);
};
