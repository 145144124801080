import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from '@reduxjs/toolkit';
import {isArray} from 'lodash';
import {toast} from 'react-toastify';

import {FETCH_STATUS} from '../../constants/app';
import type {RootState} from '../../store';
import {ListPayload} from '../../types';
import {prependListData} from '../../utils/apiHelper';
import {createEquipmentThunk, searchEquipmentsThunk} from '../thunks';

// Define a type for the slice state

export interface EquipmentPayload {
  id: string;
  name: string;
}

interface EquipmentState {
  equipments?: ListPayload<EquipmentPayload>;
  status: FETCH_STATUS;
  errorMsg?: string;
}

const initialState: EquipmentState = {
  equipments: undefined,
  status: FETCH_STATUS.IDLE,
  errorMsg: undefined,
};

export const equipmentSlice = createSlice({
  name: 'equipment',
  initialState,
  reducers: {
    resetSelections: (state, action) => {},
  },
  extraReducers: builder => {
    builder
      .addCase(createEquipmentThunk.pending, (state, action) => {})
      .addCase(createEquipmentThunk.fulfilled, (state, action) => {
        const {payload} = action;
        prependListData(state.equipments, payload);
      })
      .addCase(createEquipmentThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
      })

      .addCase(searchEquipmentsThunk.pending, (state, action) => {})
      .addCase(searchEquipmentsThunk.fulfilled, (state, action) => {
        const {payload} = action;
        state.equipments = payload;
      })
      .addCase(searchEquipmentsThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
        state.equipments = undefined;
      })

      .addMatcher(isFulfilled, (state, action) => {
        state.status = FETCH_STATUS.IDLE;
      })
      .addMatcher(isPending, (state, action) => {
        state.status = FETCH_STATUS.LOADING;
      })
      .addMatcher(isRejected, (state, action) => {
        state.status = FETCH_STATUS.FAILED;
      });
  },
});

export const {resetSelections} = equipmentSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getEquipmentLoadingStatus = (state: RootState) =>
  state.equipment.status;
export const getEquipments = (state: RootState) => state.equipment.equipments;

export default equipmentSlice.reducer;
