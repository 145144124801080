export enum NotificationType {
  CLIENT_DELIVERY_PAYMENT_APPROVED = 'CLIENT_DELIVERY_PAYMENT_APPROVED',
  CLIENT_FINAL_PAYMENT_APPROVED = 'CLIENT_FINAL_PAYMENT_APPROVED',
  CLIENT_ORDER_GRABBED = 'CLIENT_ORDER_GRABBED',
  CLIENT_DELIVERY_DATE_UPDATED = 'CLIENT_DELIVERY_DATE_UPDATED',
  CLIENT_WORKER_REACHING = 'CLIENT_WORKER_REACHING',
  CLIENT_WORKER_REACHED = 'CLIENT_WORKER_REACHED',
  CLIENT_BILL_INIT = 'CLIENT_BILL_INIT',
  WORKER_ORDER_GRABBED = 'WORKER_ORDER_GRABBED',
  WORKER_DELIVERY_DATE_UPDATED = 'WORKER_DELIVERY_DATE_UPDATED',
  WORKER_PAY_DISTRIBUTED = 'WORKER_PAY_DISTRIBUTED',
  ADMIN_CLIENT_PAY_PENDING = 'ADMIN_CLIENT_PAY_PENDING',
  ADMIN_WORKER_REGISTERED = 'ADMIN_WORKER_REGISTERED',
  ADMIN_CLIENT_FEEDBACK_PENDING = 'ADMIN_CLIENT_FEEDBACK_PENDING',
  ADMIN_WORKER_REWARD_PENDING = 'ADMIN_WORKER_REWARD_PENDING',
}
