import 'react-photo-view/dist/react-photo-view.css';

import {PhotoProvider} from 'react-photo-view';

import DialogProvider from './DialogContext';
import DrawerProvider from './DrawerContext';
import {SocketProvider} from './SocketContext';
import {ToastProvider} from './ToastContext';

export * from './DialogContext';
export * from './SocketContext';
export * from './ThemeContext';

interface IProps {
  children: React.ReactNode;
}

const Context = ({children}: IProps) => {
  return (
    <SocketProvider>
      <DialogProvider>
        <PhotoProvider>
          <DrawerProvider>
            <ToastProvider>{children}</ToastProvider>
          </DrawerProvider>
        </PhotoProvider>
      </DialogProvider>
      ;
    </SocketProvider>
  );
};

export default Context;
