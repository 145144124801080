import {LinearProgress} from '@mui/material';
import {Suspense} from 'react';
import {Outlet} from 'react-router-dom';

import SideNavBar from '../../../components/SideNavBar';
import styles from './DashboardLayout.module.scss';

interface IProps {
  navBarConfig: Record<string, any>;
}

function DashboardLayout({navBarConfig}: IProps) {
  // const [drawerOpen, setDrawerOpen] = useState(false);

  // const toggleDrawer = (bool = !drawerOpen) => {
  //   setDrawerOpen(bool);
  // };

  return (
    <div className={styles.root}>
      <SideNavBar config={navBarConfig} />
      <div className={styles.content}>
        <Suspense fallback={<LinearProgress />}>
          <Outlet />
        </Suspense>
      </div>
    </div>
  );
}

export default DashboardLayout;
