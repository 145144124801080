import {createAsyncThunk} from '@reduxjs/toolkit';

import {getGoogleAutocompleteApi, getGooglePlaceDetailsApi} from '../api';
import {axiosClient} from '../axios';
import {UseRequestPayload} from '../axios/axios';

export const getGoogleAutocompleteThunk = createAsyncThunk(
  'member/getGoogleAutocomplete',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(getGoogleAutocompleteApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const getGooglePlaceDetailsThunk = createAsyncThunk(
  'member/getGooglePlaceDetails',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(getGooglePlaceDetailsApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);
