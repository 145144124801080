import {ApiMethod} from '../axios/axios';

// POST METHODS
export const createServiceApi = {
  url: '/services',
  method: ApiMethod.POST,
};

// GET METHODS
export const getServiceByIdApi = {
  url: '/services',
  method: ApiMethod.GET,
};

export const searchServicesApi = {
  url: '/services/search',
  method: ApiMethod.GET,
};

// PUT METHODS
export const putServiceApi = {
  url: '/services',
  method: ApiMethod.PUT,
};

// DELETE METHODS
export const deleteServiceByIdApi = {
  url: '/services',
  method: ApiMethod.DELETE,
};
