import {createAsyncThunk} from '@reduxjs/toolkit';

import {ACCESS_TOKEN_KEY} from '../../constants';
import {loadToken} from '../../utils/authHelper';
import {getAccountInfoApi, usernameLoginApi} from '../api/authApi';
import {axiosClient} from '../axios';
import {applyToken} from '../reducers/authSlice';

export const usernameLoginThunk = createAsyncThunk(
  'auth/usernameLogin',
  async (
    payload: {body: {username: string; password: string}},
    {rejectWithValue, dispatch},
  ) => {
    try {
      const response = await axiosClient.use(usernameLoginApi, payload);
      console.log('response', response);
      // The value we return becomes the `fulfilled` action payload
      const accessToken = response?.data?.data?.access_token;
      console.log('accessToken', accessToken);
      if (accessToken) {
        const details: any = await loadToken(
          ACCESS_TOKEN_KEY,
          accessToken,
          process.env.REACT_ENCRYPT_TOKEN_SECRET || '',
        );
        dispatch(applyToken(accessToken));
        return {accessToken, details};
      }
    } catch (error: any) {
      window.localStorage.removeItem(ACCESS_TOKEN_KEY);
      return rejectWithValue(error?.data?.message);
    }
  },
);

export const getAccountInfoThunk = createAsyncThunk(
  'auth/getAccountInfo',
  async (_, {rejectWithValue}) => {
    try {
      const response = await axiosClient.use(getAccountInfoApi);
      console.log('getAccountInfo responseresponse', response);
      return response;

      // The value we return becomes the `fulfilled` action payload
    } catch (error: any) {
      console.log('getAccountInfo ERROR', error?.message);
      return rejectWithValue(error?.data?.message);
    }
  },
);

// export const putAccountInfoThunk = createAsyncThunk(
//   'auth/putAccountInfo',
//   async (payload: Record<string, any>, {rejectWithValue}) => {
//     try {
//       const response = await axiosClient.use(putAccountInfoApi, payload);
//       console.log('putAccountInfo responseresponse', response);
//       return response;

//       // The value we return becomes the `fulfilled` action payload
//     } catch (error: any) {
//       console.log('putAccountInfo ERROR', error?.message);
//       return rejectWithValue(error?.data?.message);
//     }
//   },
// );
