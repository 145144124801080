import {ApiMethod} from '../axios/axios';

// POST METHODS
export const createHighlightApi = {
  url: '/highlights',
  method: ApiMethod.POST,
};

// GET METHODS
export const searchHighlightsApi = {
  url: '/highlights/search',
  method: ApiMethod.GET,
};

export const getHighlightByIdApi = {
  url: '/highlights',
  method: ApiMethod.GET,
};

// PUT METHODS
export const putHighlightApi = {
  url: '/highlights',
  method: ApiMethod.PUT,
};
