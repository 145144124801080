export enum HighlightStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum HighlightType {
  PROMOTION = 'PROMOTION',
  CONSTRUCTION = 'CONSTRUCTION',
  EQUIPMENT = 'EQUIPMENT',
  ACCESSORY = 'ACCESSORY',
}
