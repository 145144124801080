import React, {useCallback} from 'react';
import {useLocalStorage} from 'usehooks-ts';

import {STORAGE_KEY} from '../constants';

const useAudioMuted = () => {
  const [muted, setMuted] = useLocalStorage(STORAGE_KEY.AUDIO_MUTED, false);

  const toggleMuted = useCallback(() => {
    if (muted) {
      setMuted(false);
    } else {
      setMuted(true);
    }
  }, [muted, setMuted]);

  return {
    muted,
    toggleMuted,
  };
};

export default useAudioMuted;
