import {ApiMethod} from '../axios/axios';

// POST METHODS
export const createEquipmentApi = {
  url: '/equipments',
  method: ApiMethod.POST,
};

// GET METHODS

export const searchEquipmentsApi = {
  url: '/equipments/search',
  method: ApiMethod.GET,
};
