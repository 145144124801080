import {ApiMethod} from '../axios/axios';

export const uploadFileApi = {
  url: 'files/upload',
  method: ApiMethod.POST,
};

export const uploadPublicFileApi = {
  url: 'files/upload/public',
  method: ApiMethod.POST,
};

export const uploadMulterPublicFileApi = {
  url: 'files/upload/multer-public',
  method: ApiMethod.POST,
};
