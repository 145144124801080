import {ApiMethod} from '../axios/axios';

// GET
export const getReviewByIdApi = {
  url: 'reviews',
  method: ApiMethod.GET,
};

export const searchReviewsApi = {
  url: 'reviews/search',
  method: ApiMethod.GET,
};

// PUT
export const putReviewApi = {
  url: 'reviews',
  method: ApiMethod.PUT,
};
