import {ApiMethod} from '../axios/axios';

// POST METHODS
export const createClientApi = {
  url: '/admins/clients/profile',
  method: ApiMethod.POST,
};

export const createWorkerApi = {
  url: '/admins/workers/profile',
  method: ApiMethod.POST,
};

export const createAdminApi = {
  url: '/admins',
  method: ApiMethod.POST,
};

// GET METHODS
export const getAnalyticsApi = {
  url: '/admins/analytics',
  method: ApiMethod.GET,
};

export const searchClientsApi = {
  url: '/admins/clients/search',
  method: ApiMethod.GET,
};

export const searchWorkersApi = {
  url: '/admins/workers/search',
  method: ApiMethod.GET,
};

export const exportClientExcelApi = {
  url: '/admins/clients/excel',
  method: ApiMethod.GET,
};

export const exportWorkerExcelApi = {
  url: '/admins/workers/excel',
  method: ApiMethod.GET,
};

export const exportAccessoryExcelApi = {
  url: '/admins/accessories/excel',
  method: ApiMethod.GET,
};

export const exportOrderExcelApi = {
  url: '/admins/orders/excel',
  method: ApiMethod.GET,
};

export const searchWorkersToAssignApi = {
  url: '/admins/workers-to-assign/search',
  method: ApiMethod.GET,
};

export const searchAdminsApi = {
  url: '/admins/search',
  method: ApiMethod.GET,
};

export const getClientByIdApi = {
  url: '/admins/clients/profile',
  method: ApiMethod.GET,
};

export const getWorkerByIdApi = {
  url: '/admins/workers/profile',
  method: ApiMethod.GET,
};

export const getAdminByIdApi = {
  url: '/admins',
  method: ApiMethod.GET,
};

// PUT METHODS

export const putClientByIdApi = {
  url: '/admins/clients/profile',
  method: ApiMethod.PUT,
};

export const putWorkerByIdApi = {
  url: '/admins/workers/profile',
  method: ApiMethod.PUT,
};

export const approveWorkerApi = {
  url: '/admins/workers/status',
  method: ApiMethod.PUT,
};

export const assignOrderApi = {
  url: '/admins/order/assign',
  method: ApiMethod.PUT,
};

export const deactivateOrderApi = {
  url: '/admins/order/deactivate',
  method: ApiMethod.PUT,
};

export const reactivateOrderApi = {
  url: '/admins/order/reactivate',
  method: ApiMethod.PUT,
};

export const deleteWorkersApi = {
  url: '/admins/workers/batch-delete',
  method: ApiMethod.PUT,
};

export const putAdminByIdApi = {
  url: '/admins',
  method: ApiMethod.PUT,
};

// DELETE
export const deleteAdminApi = {
  url: '/admins',
  method: ApiMethod.DELETE,
};
