import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
  PayloadAction,
} from '@reduxjs/toolkit';
import {isArray} from 'lodash';
import {toast} from 'react-toastify';

import {FETCH_STATUS} from '../../constants/app';
import {HighlightStatus, HighlightType} from '../../enums';
import type {RootState} from '../../store';
import {
  deleteDataFromList,
  prependListData,
  putListData,
  updateSelectedData,
} from '../../utils/apiHelper';
import {
  createPostThunk,
  deletePostByIdThunk,
  getPostByIdThunk,
  putPostByIdThunk,
  searchPostsThunk,
} from '../thunks/postThunk';

// Define a type for the slice state

export interface PostPayload {
  id: string;
  title: string;
  image: string;
  url?: string;
  sort_weigh: number;
  status: HighlightStatus;
  type: HighlightType;
}

export interface ListPayload<Item> {
  count: number;
  has_more: boolean;
  list: Item[];
  total: number;
}

interface PostState {
  posts?: ListPayload<PostPayload>;
  selectedPost?: PostPayload;
  status: FETCH_STATUS;
  errorMsg?: string;
}

const initialState: PostState = {
  posts: undefined,
  selectedPost: undefined,
  status: FETCH_STATUS.IDLE,
  errorMsg: undefined,
};

export const postSlice = createSlice({
  name: 'highlight',
  initialState,
  reducers: {
    resetSelections: (state, action) => {
      state.selectedPost = undefined;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createPostThunk.pending, (state, action) => {})
      .addCase(createPostThunk.fulfilled, (state, action) => {
        const {payload} = action;
        prependListData(state.posts, payload);
      })
      .addCase(createPostThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
      })

      .addCase(searchPostsThunk.pending, (state, action) => {
        state.posts = undefined;
      })
      .addCase(searchPostsThunk.fulfilled, (state, action) => {
        const {payload} = action;
        state.posts = payload;
      })
      .addCase(searchPostsThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
        state.posts = undefined;
      })

      .addCase(getPostByIdThunk.pending, (state, action) => {
        state.selectedPost = undefined;
      })
      .addCase(getPostByIdThunk.fulfilled, (state, action) => {
        const {payload} = action;
        state.selectedPost = payload;
      })
      .addCase(getPostByIdThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
      })

      .addCase(putPostByIdThunk.pending, (state, action) => {})
      .addCase(putPostByIdThunk.fulfilled, (state, action) => {
        const {payload} = action;
        updateSelectedData(state.selectedPost, payload);
        putListData(state.posts, payload);
      })
      .addCase(putPostByIdThunk.rejected, (state, action) => {
        const {payload} = action;
        console.log('payload', payload);
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
      })

      .addCase(deletePostByIdThunk.pending, (state, action) => {})
      .addCase(deletePostByIdThunk.fulfilled, (state, action) => {
        const {payload, meta} = action;
        deleteDataFromList(state.posts, payload?.id);
      })
      .addCase(deletePostByIdThunk.rejected, (state, action) => {
        const {payload} = action;
        // console.log('payload', payload);
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
      })

      .addMatcher(isFulfilled, (state, action) => {
        state.status = FETCH_STATUS.IDLE;
      })
      .addMatcher(isPending, (state, action) => {
        state.status = FETCH_STATUS.LOADING;
      })
      .addMatcher(isRejected, (state, action) => {
        state.status = FETCH_STATUS.FAILED;
      });
  },
});

export const {resetSelections} = postSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getPostLoadingStatus = (state: RootState) => state.post.status;
export const getPosts = (state: RootState) => state.post.posts;
export const getSelectedPost = (state: RootState) => state.post.selectedPost;

export default postSlice.reducer;
