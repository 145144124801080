import {CloseRounded} from '@mui/icons-material';
import {LoadingButton} from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import {isArray} from 'lodash';
// import {AlertDialog, Button, Center, Text, View} from 'native-base';
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';

export interface DialogButtonProps {
  label: string;
  onClick?: ({
    close,
  }: {
    close: (noReset?: boolean) => void;
    load: () => void;
  }) => void;
  color?: string;
  noReset?: boolean;
}

interface IProps {
  title?: string;
  content?: string | JSX.Element;
  buttons?: DialogButtonProps[];
  children?: React.ReactNode;
  defaultOpen?: boolean;
  showHeader?: boolean;
  showFooter?: boolean;
  showCloseButton?: boolean;
  showButton?: boolean;
  allowBackdropClose?: boolean;
  onClose?: () => void;
}

export interface DialogStateProps {
  title?: string;
  content?: string | JSX.Element;
  buttons?: DialogButtonProps[];
  defaultOpen?: boolean;
  showHeader?: boolean;
  showFooter?: boolean;
  showCloseButton?: boolean;
  showButton?: boolean;
  allowBackdropClose?: boolean;
  onClose?: () => void;
}

export interface OverlaysDialogHandleProps {
  close: (noReset?: boolean) => void;
  open: () => void;
  reset: (props?: DialogStateProps) => void;
}

const DialogModel = forwardRef<OverlaysDialogHandleProps, IProps>(
  (
    {
      title = '',
      content = '',
      buttons,
      defaultOpen = false,
      showHeader = true,
      showFooter = true,
      showCloseButton = true,
      showButton = true,
      allowBackdropClose = true,
      onClose: onCloseCallback = () => {},
      children,
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(defaultOpen);
    const [props, setProps] = useState({
      title,
      content,
      buttons,
      showCloseButton,
      showButton,
      defaultOpen,
    });
    const [buttonLoading, setButtonLoading] = useState<Record<any, boolean>>(
      {},
    );

    const onClose = (noReset?: boolean) => {
      setIsOpen(false);
      if (!noReset) {
        onReset();
      }
      onCloseCallback();
      setButtonLoading({});
    };

    const buttonLoadOnClick = useCallback((index: number, bool?: boolean) => {
      setButtonLoading(prev => ({
        ...prev,
        [index]: bool === false ? false : true,
      }));
    }, []);

    const onOpen = () => setIsOpen(true);
    const onReset = (props: DialogStateProps = {}) =>
      setProps({
        title,
        content,
        buttons,
        showCloseButton,
        showButton,
        defaultOpen,
        ...props,
      });

    const cancelRef = React.useRef(null);

    useImperativeHandle(ref, () => ({
      close: onClose,
      open: onOpen,
      reset: onReset,
    }));

    const renderHeader = () => (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <DialogTitle alignItems={'center'}>{props.title}</DialogTitle>
        {props.showCloseButton && (
          <Box>
            <IconButton onClick={() => onClose()}>
              <CloseRounded />
            </IconButton>
          </Box>
        )}
      </Box>
    );

    const renderContent = () => (
      <DialogContent>
        {children ? (
          children
        ) : typeof props.content === 'string' ? (
          <Box textAlign={'center'}>{props.content}</Box>
        ) : (
          <>{props.content}</>
        )}
      </DialogContent>
    );

    const renderFooter = () => (
      <>
        {showButton && (
          <Box justifyContent={'center'} pt={1}>
            <DialogActions>
              {isArray(props?.buttons) ? (
                props.buttons.map((obj, i) => {
                  return (
                    <LoadingButton
                      sx={obj.color ? {backgroundColor: obj.color} : {}}
                      loading={buttonLoading[i]}
                      variant="contained"
                      key={`overlay-dialog-index-${i}`}
                      onClick={() =>
                        obj.onClick &&
                        obj.onClick({
                          close: onClose,
                          load: (bool?: boolean) => buttonLoadOnClick(i, bool),
                        })
                      }>
                      {obj.label}
                    </LoadingButton>
                  );
                })
              ) : (
                // DEFAULT BUTTON
                <Button
                  variant="contained"
                  onClick={() => onClose()}
                  ref={cancelRef}>
                  確認
                </Button>
              )}
            </DialogActions>
          </Box>
        )}
      </>
    );

    return (
      <Box>
        <Dialog open={isOpen} onClose={() => allowBackdropClose && onClose()}>
          {isOpen && (
            <Box sx={{overflowX: 'hidden', minWidth: '600px'}}>
              <DialogContent>
                {showHeader && renderHeader()}
                {renderContent()}
                {showFooter && renderFooter()}
              </DialogContent>
            </Box>
          )}
        </Dialog>
      </Box>
    );
  },
);

export default DialogModel;
