import {Box} from '@mui/material';

import MainLogo from '../components/MainLogo';
import styles from './Main.module.scss';

const Main = () => {
  return (
    <Box className={styles.root}>
      <MainLogo />
    </Box>
  );
};

export default Main;
