import {createAsyncThunk} from '@reduxjs/toolkit';

import {
  createPayApi,
  getPayByIdApi,
  getPayRequestByOrderIdApi,
  putPayByIdApi,
  searchPayRequestsApi,
  searchPaysApi,
} from '../api';
import {axiosClient} from '../axios';
import {UseRequestPayload} from '../axios/axios';
// POST

export const createPayThunk = createAsyncThunk(
  'pay/createPay',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(createPayApi, payload);
      return {...response?.data?.data};
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

// GET

export const getPayByIdThunk = createAsyncThunk(
  'pay/getPayById',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(getPayByIdApi, payload);
      return {...response?.data?.data};
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const getPayRequestByOrderIdThunk = createAsyncThunk(
  'pay/getPayRequestByOrderId',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(
        getPayRequestByOrderIdApi,
        payload,
      );
      return {...response?.data?.data};
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const searchPaysThunk = createAsyncThunk(
  'pay/searchPays',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(searchPaysApi, payload);
      return {...response?.data?.data};
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const searchPayRequestsThunk = createAsyncThunk(
  'pay/searchPayRequests',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(searchPayRequestsApi, payload);
      return {...response?.data?.data};
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

// PUT
export const putPayByIdThunk = createAsyncThunk(
  'pay/putPayById',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(putPayByIdApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);
