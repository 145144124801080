import {createAsyncThunk} from '@reduxjs/toolkit';

import {
  createHighlightApi,
  getHighlightByIdApi,
  putHighlightApi,
  searchHighlightsApi,
} from '../api/highlightApi';
import {axiosClient} from '../axios';
import {UseRequestPayload} from '../axios/axios';

export const createHighlightThunk = createAsyncThunk(
  'highlight/createHighlight',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(createHighlightApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const getHighlightByIdThunk = createAsyncThunk(
  'highlight/getHighlightById',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(getHighlightByIdApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const searchHighlightsThunk = createAsyncThunk(
  'highlight/searchHighlights',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(searchHighlightsApi, payload);
      return {...response?.data?.data, type: payload?.query?.type || ''};
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const putHighlightThunk = createAsyncThunk(
  'highlight/putHighlight',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(putHighlightApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      console.log('putHighlightThunk error', error);
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);
