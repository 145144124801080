import {ApiMethod} from '../axios/axios';

// GET METHODS
export const searchPaymentsApi = {
  url: '/payments/search/admin/all',
  method: ApiMethod.GET,
};

export const getPaymentByIdApi = {
  url: 'payments',
  method: ApiMethod.GET,
};

// PUT METHODS
export const approveManualPaymentApi = {
  url: '/payments/approve-manual-payment',
  method: ApiMethod.PUT,
};
