import {ApiMethod} from '../axios/axios';

export const getGoogleAutocompleteApi = {
  url: 'members/address/autocomplete',
  method: ApiMethod.GET,
};

export const getGooglePlaceDetailsApi = {
  url: 'members/address/place_details',
  method: ApiMethod.GET,
};
