import {ApiMethod} from '../axios/axios';

// POST METHODS
export const createPostApi = {
  url: '/posts',
  method: ApiMethod.POST,
};

// GET METHODS
export const searchPostsApi = {
  url: '/posts/search',
  method: ApiMethod.GET,
};

export const getPostByIdApi = {
  url: '/posts',
  method: ApiMethod.GET,
};

// PUT METHOD
export const putPostByIdApi = {
  url: '/posts',
  method: ApiMethod.PUT,
};

// DELETE METHOD
export const deletePostByIdApi = {
  url: '/posts',
  method: ApiMethod.DELETE,
};
