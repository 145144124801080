import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
  PayloadAction,
} from '@reduxjs/toolkit';
import {isArray} from 'lodash';
import {toast} from 'react-toastify';

import {FETCH_STATUS} from '../../constants/app';
import {HighlightStatus, HighlightType} from '../../enums';
import type {RootState} from '../../store';
import {
  prependListData,
  putListData,
  updateSelectedData,
} from '../../utils/apiHelper';
import {
  createHighlightThunk,
  getHighlightByIdThunk,
  putHighlightThunk,
  searchHighlightsThunk,
} from '../thunks/highlightThunk';

// Define a type for the slice state

export interface HighlightPayload {
  id: string;
  title: string;
  image: string;
  url?: string;
  sort_weigh: number;
  status: HighlightStatus;
  type: HighlightType;
}

export interface ListPayload<Item> {
  count: number;
  has_more: boolean;
  list: Item[];
  total: number;
}

interface HighlightState {
  promotions?: ListPayload<HighlightPayload>;
  constructions?: ListPayload<HighlightPayload>;
  equipments?: ListPayload<HighlightPayload>;
  accessories?: ListPayload<HighlightPayload>;
  selectedPromotion?: HighlightPayload;
  selectedConstruction?: HighlightPayload;
  selectedEquipment?: HighlightPayload;
  selectedAccessory?: HighlightPayload;
  status: FETCH_STATUS;
  errorMsg?: string;
}

const initialState: HighlightState = {
  promotions: undefined,
  selectedPromotion: undefined,
  selectedConstruction: undefined,
  selectedEquipment: undefined,
  selectedAccessory: undefined,
  status: FETCH_STATUS.IDLE,
  errorMsg: undefined,
};

export const highlightSlice = createSlice({
  name: 'highlight',
  initialState,
  reducers: {
    resetSelections: (state, action) => {
      state.selectedPromotion = undefined;
      state.selectedConstruction = undefined;
      state.selectedEquipment = undefined;
      state.selectedAccessory = undefined;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createHighlightThunk.pending, (state, action) => {})
      .addCase(createHighlightThunk.fulfilled, (state, action) => {
        const {payload} = action;
        const {type} = payload;
        switch (type) {
          case HighlightType.PROMOTION:
            prependListData(state.promotions, payload);
            break;
          case HighlightType.CONSTRUCTION:
            prependListData(state.constructions, payload);
            break;
          case HighlightType.EQUIPMENT:
            prependListData(state.equipments, payload);
            break;
          case HighlightType.ACCESSORY:
            prependListData(state.accessories, payload);
            break;
          default:
            return;
        }
      })
      .addCase(createHighlightThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
      })

      .addCase(searchHighlightsThunk.pending, (state, action) => {
        state.promotions = undefined;
      })
      .addCase(searchHighlightsThunk.fulfilled, (state, action) => {
        const {payload} = action;
        const {type, ...data} = payload;
        switch (type) {
          case HighlightType.PROMOTION:
            state.promotions = data;
            break;
          case HighlightType.CONSTRUCTION:
            state.constructions = data;
            break;
          case HighlightType.EQUIPMENT:
            state.equipments = data;
            break;
          case HighlightType.ACCESSORY:
            state.accessories = data;
            break;
          default:
            return;
        }
      })
      .addCase(searchHighlightsThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
        state.promotions = undefined;
      })

      .addCase(getHighlightByIdThunk.pending, (state, action) => {})
      .addCase(getHighlightByIdThunk.fulfilled, (state, action) => {
        const {payload} = action;
        const type = payload?.type;
        switch (type) {
          case HighlightType.PROMOTION:
            state.selectedPromotion = payload;
            break;
          case HighlightType.CONSTRUCTION:
            state.selectedConstruction = payload;
            break;
          case HighlightType.EQUIPMENT:
            state.selectedEquipment = payload;
            break;
          case HighlightType.ACCESSORY:
            state.selectedAccessory = payload;
            break;
          default:
            return;
        }
      })
      .addCase(getHighlightByIdThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
      })

      .addCase(putHighlightThunk.pending, (state, action) => {})
      .addCase(putHighlightThunk.fulfilled, (state, action) => {
        const {payload} = action;
        const type = payload?.type;
        switch (type) {
          case HighlightType.PROMOTION:
            updateSelectedData(state.selectedPromotion, payload);
            putListData(state.promotions, payload);
            break;
          case HighlightType.CONSTRUCTION:
            updateSelectedData(state.selectedConstruction, payload);
            putListData(state.constructions, payload);
            break;
          case HighlightType.EQUIPMENT:
            updateSelectedData(state.selectedEquipment, payload);
            putListData(state.equipments, payload);
            break;
          case HighlightType.ACCESSORY:
            updateSelectedData(state.selectedAccessory, payload);
            putListData(state.accessories, payload);
            break;
          default:
            return;
        }
      })
      .addCase(putHighlightThunk.rejected, (state, action) => {
        const {payload} = action;
        console.log('payload', payload);
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
      })

      .addMatcher(isFulfilled, (state, action) => {
        state.status = FETCH_STATUS.IDLE;
      })
      .addMatcher(isPending, (state, action) => {
        state.status = FETCH_STATUS.LOADING;
      })
      .addMatcher(isRejected, (state, action) => {
        state.status = FETCH_STATUS.FAILED;
      });
  },
});

export const {resetSelections} = highlightSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getHighlightLoadingStatus = (state: RootState) =>
  state.highlight.status;
export const getPromotions = (state: RootState) => state.highlight.promotions;
export const getConstructions = (state: RootState) =>
  state.highlight.constructions;
export const getEquipments = (state: RootState) => state.highlight.equipments;
export const getAccessories = (state: RootState) => state.highlight.accessories;
export const getSelectedPromotion = (state: RootState) =>
  state.highlight.selectedPromotion;
export const getSelectedConstruction = (state: RootState) =>
  state.highlight.selectedConstruction;
export const getSelectedEquipment = (state: RootState) =>
  state.highlight.selectedEquipment;
export const getSelectedAccessory = (state: RootState) =>
  state.highlight.selectedAccessory;

export default highlightSlice.reducer;
