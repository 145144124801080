import {createAsyncThunk} from '@reduxjs/toolkit';

import {
  createAccessoryApi,
  deleteAccessoryByIdApi,
  getAccessoryByIdApi,
  putAccessoryByIdApi,
  searchAccessoriesApi,
} from '../api/accessoryApi';
import {axiosClient} from '../axios';
import {UseRequestPayload} from '../axios/axios';

// POST
export const createAccessoryThunk = createAsyncThunk(
  'accessory/createAccessory',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      console.log('payload', payload);
      const response = await axiosClient.use(createAccessoryApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

// GET
export const getAccessoryByIdThunk = createAsyncThunk(
  'accessory/getAccessoryById',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(getAccessoryByIdApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const searchAccessoriesThunk = createAsyncThunk(
  'accessory/searchAccessories',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(searchAccessoriesApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

// PUT
export const putAccessoryByIdThunk = createAsyncThunk(
  'accessory/putAccessoryById',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(putAccessoryByIdApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

// DELETE
export const deleteAccessoryByIdThunk = createAsyncThunk(
  'service/deleteAccessoryById',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(deleteAccessoryByIdApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);
