import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
  PayloadAction,
} from '@reduxjs/toolkit';
import {isArray} from 'lodash';
import {toast} from 'react-toastify';

import {ACCESS_TOKEN_KEY, FETCH_STATUS} from '../../constants/app';
import {AdminRight, JwtAccountType} from '../../enums';
import type {RootState} from '../../store';
import {encryptToken, loadToken} from '../../utils/authHelper';
import {getAccountInfoThunk, usernameLoginThunk} from '../thunks';

// Define a type for the slice state

export interface UserPayload {
  id: string;
  right: AdminRight;
  account_type: JwtAccountType;
}

interface AuthState {
  accessToken?: string;
  user?: UserPayload;
  status: FETCH_STATUS;
  errorMsg?: string;
}

const initialState: AuthState = {
  accessToken: undefined,
  user: undefined,
  status: FETCH_STATUS.IDLE,
  errorMsg: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state, action: PayloadAction) => {
      state.user = undefined;
      state.accessToken = undefined;
      state.errorMsg = undefined;
      window.localStorage.removeItem(ACCESS_TOKEN_KEY);
    },
    applyToken: (state, action: PayloadAction<string | null | undefined>) => {
      const {payload} = action;
      const token = payload;
      if (token && typeof token === 'string') {
        const details = loadToken(
          ACCESS_TOKEN_KEY,
          token,
          process.env.REACT_ENCRYPT_TOKEN_SECRET || '',
        );
        console.log('token details', details);
        if (details) {
          state.accessToken = encryptToken(
            token,
            process.env.REACT_ENCRYPT_TOKEN_SECRET || '',
          );
          state.user = {
            id: details?.sub,
            right: details?.right,
            account_type: details?.account_type,
          };
        } else {
          state.user = undefined;
          window.localStorage.removeItem(ACCESS_TOKEN_KEY);
        }
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(usernameLoginThunk.fulfilled, (state, action) => {
        const {payload} = action;
        if (payload?.details) {
          state.accessToken = encryptToken(
            payload?.accessToken,
            process.env.REACT_ENCRYPT_TOKEN_SECRET || '',
          );
        }
      })
      .addCase(usernameLoginThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
          state.errorMsg = isArray(payload) ? payload[0] : payload || 'ERROR';
        }
        state.user = undefined;
      })

      .addCase(getAccountInfoThunk.fulfilled, (state, action) => {
        const {payload} = action;
        state.user = payload.data;
      })

      .addMatcher(isFulfilled, (state, action) => {
        state.status = FETCH_STATUS.IDLE;
      })
      .addMatcher(isPending, (state, action) => {
        state.status = FETCH_STATUS.LOADING;
      })
      .addMatcher(isRejected, (state, action) => {
        state.status = FETCH_STATUS.FAILED;
      });
  },
});

export const {applyToken, logout} = authSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getUser = (state: RootState) => state.auth.user;
export const getAccessToken = (state: RootState) => state.auth.accessToken;
export const getAuthLoadingStatus = (state: RootState) => state.auth.status;

export default authSlice.reducer;
