import {createAsyncThunk} from '@reduxjs/toolkit';

import {
  approveManualPaymentApi,
  getPaymentByIdApi,
  searchPaymentsApi,
} from '../api/paymentApi';
import {axiosClient} from '../axios';
import {UseRequestPayload} from '../axios/axios';

// GET
export const searchPaymentsThunk = createAsyncThunk(
  'payment/searchPayments',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(searchPaymentsApi, payload);
      return {...response?.data?.data, type: payload?.query?.type || ''};
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const getPaymentByIdThunk = createAsyncThunk(
  'payment/getPaymentById',
  async (payload: UseRequestPayload = {}, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(getPaymentByIdApi, payload);
      console.log('getPaymentByIdThunk response', response);
      // The value we return becomes the `fulfilled` action payload
      const data = response?.data?.data;
      return data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

// PUT
export const approveManualPaymentThunk = createAsyncThunk(
  'payment/approveManualPayment',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(approveManualPaymentApi, payload);
      return {...response?.data?.data, type: payload?.query?.type || ''};
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);
