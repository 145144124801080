import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';

import {useSocket} from '../../../context';
import styles from './NavBarButton.module.scss';

interface IProps {
  label: string;
  path: string;
  match: string;
}

function NavBarButton({label, path, match}: IProps) {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [open, setOpen] = useState(false);

  const matchCurrentPath = useMemo(() => {
    const currentPath = location.pathname;
    const regex = RegExp(match.replace('*', '(/[a-zA-Z]+){0,}'), 'ig');
    if (!match.endsWith('*')) {
      return currentPath === match;
    }
    return regex.test(currentPath);
  }, [location.pathname, path]);

  const navigateToPath = () => {
    navigate(path);
  };

  return (
    <>
      <div
        onClick={navigateToPath}
        className={`${styles.root} ${
          matchCurrentPath ? styles.currentPath : ''
        }`}>
        {t(label)}
      </div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{t('alert')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('alert_streaming_navigate')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            onClick={() => {
              setOpen(false);
              navigate(path);
            }}
            autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default NavBarButton;
