export enum PostRecipientType {
  ALL = 'ALL',
  CLIENT = 'CLIENT',
  WORKER = 'WORKER',
}

export enum PostStatus {
  ACTIVE = 'ACTIVE',
  HIDDEN = 'HIDDEN',
}
