import {createAsyncThunk} from '@reduxjs/toolkit';
import {isArray} from 'lodash';
import {toast} from 'react-toastify';

import {
  uploadFileApi,
  uploadMulterPublicFileApi,
  uploadPublicFileApi,
} from '../api/fileApi';
import {axiosClient, axiosFileUploadClient} from '../axios';

interface uploadFilePayload {
  path: string[];
  files: {name: string; data: string}[];
}

export const uploadFileThunk = createAsyncThunk(
  'file/uploadFile',
  async (payload: {body: uploadFilePayload}, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosFileUploadClient.use(uploadFileApi, payload);
      console.log('uploadFileThunk response', response);
      // The value we return becomes the `fulfilled` action payload
      const data = response?.data?.data;
      return data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      if (errorMsg) {
        toast.error(isArray(errorMsg) ? errorMsg[0] : errorMsg || 'ERROR');
      }

      return rejectWithValue(error?.data?.message);
    }
  },
);

export const uploadPublicFileThunk = createAsyncThunk(
  'file/uploadPublicFile',
  async (payload: {body: uploadFilePayload}, {rejectWithValue, dispatch}) => {
    try {
      console.log('payload', payload);
      const response = await axiosClient.use(uploadPublicFileApi, payload);
      console.log('uploadFileThunk response', response);
      // The value we return becomes the `fulfilled` action payload
      const data = response?.data?.data;
      return data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      if (errorMsg) {
        toast.error(isArray(errorMsg) ? errorMsg[0] : errorMsg || 'ERROR');
      }

      return rejectWithValue(error?.data?.message);
    }
  },
);

export const uploadMulterPublicFileThunk = createAsyncThunk(
  'file/uploadMulterPublicFile',
  async (payload: {body: FormData}, {rejectWithValue, dispatch}) => {
    try {
      console.log('payload', payload);
      const response = await axiosFileUploadClient.use(
        uploadMulterPublicFileApi,
        payload,
      );
      console.log('uploadMulterFileThunk response', response);
      // The value we return becomes the `fulfilled` action payload
      const data = response?.data?.data;
      return data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      if (errorMsg) {
        toast.error(isArray(errorMsg) ? errorMsg[0] : errorMsg || 'ERROR');
      }

      return rejectWithValue(error?.data?.message);
    }
  },
);
