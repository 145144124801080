import {createAsyncThunk} from '@reduxjs/toolkit';

import {getAppConfigApi, putAppConfigApi} from '../api/appApi';
import {axiosClient} from '../axios';
import {UseRequestPayload} from '../axios/axios';

export const getAppConfigThunk = createAsyncThunk(
  'app/getAppConfig',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(getAppConfigApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const putAppConfigThunk = createAsyncThunk(
  'app/putAppConfig',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(putAppConfigApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);
