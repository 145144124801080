import {useMemo} from 'react';
import {useSelector} from 'react-redux';

import {getUser} from '../services/reducers/authSlice';

export const useCurrentUser = () => {
  const user = useSelector(getUser);

  return useMemo(() => user, [user]);
};
