import {Button, Stack, Typography} from '@mui/material';
import React, {useCallback} from 'react';

interface IProps {
  message: string;
  label?: string;
  caption?: string;
  onConfirm?: () => void;
}

const ActionToast = ({message, label, caption, onConfirm}: IProps) => {
  const confirm = useCallback(() => {
    onConfirm && onConfirm();
  }, [onConfirm]);

  return (
    <Stack
      display={'flex'}
      flexDirection={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      px={1}>
      <Stack>
        <Typography>{message}</Typography>
        {caption && <Typography variant="caption">{caption}</Typography>}
      </Stack>
      <Button variant="contained" onClick={confirm} sx={{py: 0.5}}>
        {label}
      </Button>
    </Stack>
  );
};

export default ActionToast;
