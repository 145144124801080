import {ExitToApp, Settings} from '@mui/icons-material';
import {Button, Divider, IconButton, List, ListSubheader} from '@mui/material';
import React from 'react';
import {useNavigate} from 'react-router-dom';

// import navbarConfig from '../configs/mainNavbarConfig';
import NavBarButton from '../features/dashboard/components/NavBarButton';
import {useAppDispatch} from '../hooks/store';
import {logout} from '../services/reducers/authSlice';
import MainLogo from './MainLogo';
import styles from './SideNavBar.module.scss';

interface IProps {
  config: Record<string, any>;
  openDrawer?: () => void;
}

function HomeNavBar({config, openDrawer}: IProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const logoutHandler = () => {
    dispatch(logout());
    // navigate("/", { replace: true })
  };

  return (
    <nav className={styles.navBar}>
      <MainLogo onClick={() => navigate('/')} />

      <div className={styles.navigateSection}>
        {config.map((obj: any, index: number) => {
          return (
            <div key={obj.label}>
              {index > 0 && <Divider sx={{color: '#FFFFFF'}} />}
              <List
                sx={{
                  width: '100%',
                  maxWidth: '200px',
                  backgroundColor: 'transparent',
                }}
                subheader={
                  <ListSubheader component="p" className={styles.subheader}>
                    {obj.label}
                  </ListSubheader>
                }>
                {obj.payload?.map((children: any) => {
                  return (
                    <NavBarButton
                      key={children.label}
                      label={children.label}
                      path={children.path}
                      match={children.match}
                    />
                  );
                })}
              </List>
            </div>
          );
        })}
      </div>
      <div className={styles.optionContainer}>
        {openDrawer && (
          <IconButton onClick={openDrawer}>
            <Settings />
          </IconButton>
        )}
        {/* <IconButton onClick={logoutHandler}>
          <ExitToApp />
        </IconButton> */}
        <Button
          onClick={logoutHandler}
          color="warning"
          className={styles.button__logout}>
          登出
        </Button>
      </div>
    </nav>
  );
}

export default HomeNavBar;
