import {Method} from 'axios';

/**
 *
 * @param api
 * @param queries
 * @returns
 */
export const setQuery = (
  api: {
    url: string;
    method: Method;
  },
  queries: Record<string, string | number> = {},
) => {
  if (!queries) return api;
  const newPath = Object.entries(queries)
    .filter(([field, value]) => !!value)
    .reduce((acc, [field, value], index) => {
      return index === 0
        ? `${acc}?${field}=${value}`
        : `${acc}&${field}=${value}`;
    }, api.url);
  return {
    ...api,
    url: newPath,
  };
};

/**
 *
 * @param state
 * @param data
 */
export const prependListData = (state: any, data: any) => {
  if (state) {
    state.count += 1;
    state.list = [data, ...state.list];
    state.total += 1;
  } else {
    state = {
      count: 1,
      has_more: false,
      list: [data],
      total: 1,
    };
  }
};

/**
 *
 * @param state
 * @property {any[]} state.list
 * @param data
 */
export const updateSelectedData = (state: any, data: any) => {
  if (state?.id === data?.id) {
    state = data;
  }
};

/**
 *
 * @param state
 * @property {any[]} state.list
 * @param data
 */
export const putListData = (state: any, data: any) => {
  if (state?.list && data?.id) {
    state.list = state.list.map((obj: any) =>
      obj.id === data.id ? data : obj,
    );
  }
};

/**
 *
 * @param state
 * @param id
 */
export const deleteDataFromList = (state: any, id = '') => {
  if (state?.list?.find((obj: any) => obj.id === id)) {
    state.count -= 1;
    state.list = state.list.filter((obj: any) => obj?.id !== id);
    state.total -= 1;
  }
};

/**
 *
 * @param response
 * @returns
 */
export const isResponseSuccess = (response: any) => {
  return response?.type?.endsWith('fulfilled');
};

export const ApiHelpers = {
  setQuery,
  prependListData,
  updateSelectedData,
  putListData,
  deleteDataFromList,
  isResponseSuccess,
};
