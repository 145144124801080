import moment from 'moment-timezone';

export const TIMEZONE = {
  HK: 'Asia/Hong_Kong',
};

export const ISO_STRING_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';

export const getTimeInString = {
  UTC: (datetime: Date | string | number = new Date()) =>
    moment(datetime).utc().format(ISO_STRING_FORMAT),
  HK: (datetime: Date | string = new Date()) =>
    moment(datetime).tz(TIMEZONE.HK).format(ISO_STRING_FORMAT),
};

export const formatTime = {
  YEAR: (datetime: Date | string | number = new Date()) =>
    moment(datetime).format('YYYY'),
  MONTH: (datetime: Date | string | number = new Date()) =>
    moment(datetime).format('YYYY-MM'),
  DAY: (datetime: Date | string | number = new Date()) =>
    moment(datetime).format('YYYY-MM-DD'),
  HOUR24: (datetime: Date | string | number = new Date()) =>
    moment(datetime).format('YYYY-MM-DD HH'),
  HOUR12: (datetime: Date | string | number = new Date()) =>
    moment(datetime)
      .format('YYYY-MM-DD Ahh')
      .replace('AM', '上午')
      .replace('PM', '下午'),
  MINUTE24: (datetime: Date | string | number = new Date()) =>
    moment(datetime).format('YYYY-MM-DD HH:mm'),
  MINUTE12: (datetime: Date | string | number = new Date()) =>
    moment(datetime)
      .format('YYYY-MM-DD Ahh:mm')
      .replace('AM', '上午')
      .replace('PM', '下午'),
  SECOND24: (datetime: Date | string | number = new Date()) =>
    moment(datetime).format('YYYY-MM-DD HH:mm:ss'),
  SECOND12: (datetime: Date | string | number = new Date()) =>
    moment(datetime)
      .format('YYYY-MM-DD Ahh:mm:ss')
      .replace('AM', '上午')
      .replace('PM', '下午'),
};
