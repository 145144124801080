export enum OrderStatus {
  PENDING_PAYMENT = 'PENDING_PAYMENT',
  PAYMENT_FAILURE = 'PAYMENT_FAILURE',
  PAYMENT_PROCESSING = 'PAYMENT_PROCESSING',
  PENDING_ACCEPT = 'PENDING_ACCEPT',
  REJECTED = 'REJECTED',
  PENDING_COMMUNICATION = 'PENDING_COMMUNICATION',
  PENDING_CONFIRM_DATE = 'PENDING_CONFIRM_DATE',
  PENDING_DELIVERY = 'PENDING_DELIVERY',
  ON_THE_WAY = 'ON_THE_WAY',
  WORKER_ARRIVED = 'WORKER_ARRIVED',
  PENDING_CONFIRM_PRICE = 'PENDING_CONFIRM_PRICE',
  PENDING_FINAL_PAYMENT = 'PENDING_FINAL_PAYMENT',
  FINAL_PAYMENT_FAILURE = 'FINAL_PAYMENT_FAILURE',
  FINAL_PAYMENT_PROCESSING = 'FINAL_PAYMENT_PROCESSING',
  FINAL_PAYMENT_DISPUTE = 'FINAL_PAYMENT_DISPUTE',
  PENDING_REVIEW = 'PENDING_REVIEW',
  ENDED = 'ENDED',
  DEACTIVATED = 'DEACTIVATED',
}

export enum ProductType {
  // BOTH = 'BOTH',
  ELECTRIC = 'ELECTRIC',
  GAS = 'GAS',
  FROST = 'FROST',
  AIR = 'AIR',
}
