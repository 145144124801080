import {BaseQueryFn} from '@reduxjs/toolkit/dist/query';
import axios, {AxiosError, AxiosRequestConfig} from 'axios';

import Axios, {ApiObject} from './axios';

export const axiosBaseQuery =
  (
    {baseUrl}: {baseUrl: string} = {baseUrl: ''},
  ): BaseQueryFn<
    | {
        api?: ApiObject | undefined;
        url: string;
        method: AxiosRequestConfig['method'];
        data?: AxiosRequestConfig['data'];
      }
    | {
        api: ApiObject | undefined;
        url?: string;
        method?: AxiosRequestConfig['method'];
        data?: AxiosRequestConfig['data'];
      },
    unknown,
    unknown
  > =>
  async param => {
    const {api, url, method, data} = param;
    try {
      let arg = {url, method, data};
      if (api && api.method && api.url) {
        arg = {...arg, url: api.url, method: api.method};
      }
      const result = await axiosInstance({
        url: api?.url || url,
        method: api?.method || method,
        data,
      });
      return {data: result.data};
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data,
        },
      };
    }
  };

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_KEY || '',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': process.env.REACT_APP_API_KEY || '*',
  },
});

export const axiosFileInstance = axios.create({
  baseURL: process.env.REACT_APP_API_KEY || '',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data',
    'Access-Control-Allow-Origin': process.env.REACT_APP_API_KEY || '*',
  },
});

export const axiosClient = new Axios(axiosInstance);
export const axiosFileUploadClient = new Axios(axiosFileInstance);
axiosFileUploadClient.setTimeout(100000);
