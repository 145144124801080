import {toast} from 'react-toastify';

import {Toast} from '../components/Notifications';
import {STORAGE_KEY} from '../constants';
import {AUDIO} from '../constants/audio';
interface ToastActionProps {
  message?: string;
  label?: string;
  caption?: string;
  onConfirm?: () => void;
}

const notificationAudio = new Audio(AUDIO.notification);

const playAlertAudio = () => {
  const storageMuted = localStorage.getItem(STORAGE_KEY.AUDIO_MUTED) || '';
  const muted = storageMuted ? JSON.parse(storageMuted) : null;
  if (!muted) {
    notificationAudio.play();
  }
};

export const toastAction = ({
  message = '',
  label = '',
  caption = '',
  onConfirm = () => {},
}: ToastActionProps) => {
  toast(
    ({closeToast}) => (
      <Toast.Action
        message={message}
        onConfirm={() => {
          onConfirm();
          closeToast && closeToast();
        }}
        label={label}
        caption={caption}
      />
    ),
    {
      type: 'info',
      autoClose: 60000,
      onOpen: playAlertAudio,
    },
  );
};
