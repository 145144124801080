export enum RemunerationStatus {
  PAID = 'PAID',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
}

export enum RemunerationMethod {
  TRANSFER = 'TRANSFER',
  ALIPAY = 'ALIPAY',
}
