export enum WorkerPayMethod {
  ALIPAY_HK = 'ALIPAY_HK',
  TRANSFER = 'TRANSFER',
}

export enum WorkerPayStatus {
  PAID = 'PAID',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
}

export enum PayFilterStatus {
  PENDING = 'PENDING',
  HANDLED = 'HANDLED',
}
