export const SIZES = {
  navBar: '60px',
};

export const BREAKPOINTS = {
  phone: {
    size: 767,
    query: '(max-width: 767px)',
  },
  tablet: {
    size: 767,
    query: '(min-width: 768px) and (max-width:1023px)',
  },
  desktop: {
    size: 1024,
    query: '(min-width: 1024px)',
  },
};
