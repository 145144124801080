import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from '@reduxjs/toolkit';
import {isArray} from 'lodash';
import {toast} from 'react-toastify';

import {FETCH_STATUS} from '../../constants/app';
import type {RootState} from '../../store';
import {ListPayload} from '../../types';
import {
  deleteDataFromList,
  prependListData,
  putListData,
  updateSelectedData,
} from '../../utils/apiHelper';
import {
  createServiceThunk,
  deleteServiceByIdThunk,
  getServiceByIdThunk,
  putServiceThunk,
  searchServicesThunk,
} from '../thunks';

// Define a type for the slice state

export interface ServicePayload {
  id: string;
  type: string;
  name: string;
}

interface ServiceState {
  services?: ListPayload<ServicePayload>;
  selectedService?: ServicePayload;
  status: FETCH_STATUS;
  errorMsg?: string;
}

const initialState: ServiceState = {
  services: undefined,
  selectedService: undefined,
  status: FETCH_STATUS.IDLE,
  errorMsg: undefined,
};

export const serviceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    resetSelections: (state, action) => {
      state.selectedService = undefined;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createServiceThunk.pending, (state, action) => {})
      .addCase(createServiceThunk.fulfilled, (state, action) => {
        const {payload} = action;
        prependListData(state.services, payload);
      })
      .addCase(createServiceThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
      })

      .addCase(searchServicesThunk.pending, (state, action) => {})
      .addCase(searchServicesThunk.fulfilled, (state, action) => {
        const {payload} = action;
        state.services = payload;
      })
      .addCase(searchServicesThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
        state.services = undefined;
      })

      .addCase(getServiceByIdThunk.pending, (state, action) => {})
      .addCase(getServiceByIdThunk.fulfilled, (state, action) => {
        const {payload} = action;
        state.selectedService = payload;
      })
      .addCase(getServiceByIdThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
      })

      .addCase(putServiceThunk.pending, (state, action) => {})
      .addCase(putServiceThunk.fulfilled, (state, action) => {
        const {payload} = action;
        updateSelectedData(state.selectedService, payload);
        putListData(state.services, payload);
      })
      .addCase(putServiceThunk.rejected, (state, action) => {
        const {payload} = action;
        console.log('payload', payload);
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
      })

      .addCase(deleteServiceByIdThunk.pending, (state, action) => {})
      .addCase(deleteServiceByIdThunk.fulfilled, (state, action) => {
        const {payload, meta} = action;
        deleteDataFromList(state.services, payload?.id);
        // if (payload?.deletedCount === 1) {
        //   deleteDataFromList(state.services, meta?.arg?.param);
        // }
      })
      .addCase(deleteServiceByIdThunk.rejected, (state, action) => {
        const {payload} = action;
        // console.log('payload', payload);
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
      })

      .addMatcher(isFulfilled, (state, action) => {
        state.status = FETCH_STATUS.IDLE;
      })
      .addMatcher(isPending, (state, action) => {
        state.status = FETCH_STATUS.LOADING;
      })
      .addMatcher(isRejected, (state, action) => {
        state.status = FETCH_STATUS.FAILED;
      });
  },
});

export const {resetSelections} = serviceSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getServiceLoadingStatus = (state: RootState) =>
  state.service.status;
export const getServices = (state: RootState) => state.service.services;
export const getSelectedService = (state: RootState) =>
  state.service.selectedService;
// export const getConstructions = (state: RootState) =>
//   state.highlight.constructions;
// export const getEquipments = (state: RootState) => state.highlight.equipments;
// export const getAccessories = (state: RootState) => state.highlight.accessories;
// export const getSelectedPromotion = (state: RootState) =>
//   state.highlight.selectedPromotion;
// export const getSelectedConstruction = (state: RootState) =>
//   state.highlight.selectedConstruction;
// export const getSelectedEquipment = (state: RootState) =>
//   state.highlight.selectedEquipment;
// export const getSelectedAccessory = (state: RootState) =>
//   state.highlight.selectedAccessory;

export default serviceSlice.reducer;
