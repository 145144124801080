import {default as momentTz} from 'moment-timezone';

import {TIMEZONES} from '../constants';

export const moment = (time: any = undefined): momentTz.Moment => {
  const momentObject = momentTz(time).tz(TIMEZONES.HK);
  return momentObject;
};

/**
 *
 * @param dateString
 * @returns
 */
export const dateFormat = (
  dateString: string | Date,
  type: 'date' | 'time' | undefined = undefined,
) => {
  let format = 'YYYY-MM-DD Ahh:mm';
  if (type === 'date') {
    format = 'YYYY-MM-DD';
  }
  if (type === 'time') {
    format = 'Ahh:mm';
  }
  return moment(dateString)
    .format(format)
    .replace('AM', '上午')
    .replace('PM', '下午');
};

/**
 *
 * @param date
 * @returns
 */
export const timeDifference = (date: string): string => {
  const now = moment();
  const createDate = moment(date);
  const minutes = now.diff(createDate, 'minutes');
  const hours = now.diff(createDate, 'hours');
  const days = now.diff(createDate, 'days');
  const weeks = now.diff(createDate, 'weeks');
  const months = now.diff(createDate, 'months');
  const years = now.diff(createDate, 'years');
  if (years > 0) {
    return `${years} 天前`;
  } else if (months > 0) {
    return `${months} 月前`;
  } else if (weeks > 0) {
    return `${weeks} 星期前`;
  } else if (days > 0) {
    return `${days} 天前`;
  } else if (hours > 0) {
    return `${hours} 小時前`;
  } else if (minutes > 0) {
    return `${minutes} 分鐘前`;
  } else {
    return '剛剛';
  }
};
