import {ApiMethod} from '../axios/axios';

export const getAppConfigApi = {
  url: 'app/config',
  method: ApiMethod.GET,
};

export const putAppConfigApi = {
  url: 'app/config',
  method: ApiMethod.PUT,
};
