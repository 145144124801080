export enum PaymentStatus {
  PAID = 'PAID',
  PENDING = 'PENDING',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
}

export enum PaymentMethod {
  FPS = 'FPS',
  TRANSFER = 'TRANSFER',
  ALIPAY_HK = 'ALIPAY_HK',
  CREDIT_CARD = 'CREDIT_CARD',
  APPLE_PAY = 'APPLE_PAY',
}

export enum PaymentPurpose {
  DELIVER_FEE = 'DELIVER_FEE',
  FINAL_BILL = 'FINAL_BILL',
}
