import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from '@reduxjs/toolkit';
import {isArray} from 'lodash';
import {toast} from 'react-toastify';

import {FETCH_STATUS} from '../../constants/app';
import type {RootState} from '../../store';
import {ListPayload} from '../../types';
import {
  deleteDataFromList,
  prependListData,
  putListData,
  updateSelectedData,
} from '../../utils/apiHelper';
import {
  createPayThunk,
  getPayByIdThunk,
  getPayRequestByOrderIdThunk,
  putPayByIdThunk,
  searchPayRequestsThunk,
  searchPaysThunk,
} from '../thunks';

// Define a type for the slice state

export interface PayPayload {
  id: string;
}

export interface PayRequestPayload {
  id: string;
}

interface PayState {
  payRequests?: ListPayload<PayRequestPayload & any>;
  pays?: ListPayload<PayPayload & any>;
  selectedPay?: PayPayload & any;
  selectedPayRequest?: PayRequestPayload & any;
  status: FETCH_STATUS;
  errorMsg?: string;
}

const initialState: PayState = {
  payRequests: undefined,
  pays: undefined,
  selectedPay: undefined,
  selectedPayRequest: undefined,
  status: FETCH_STATUS.IDLE,
  errorMsg: undefined,
};

export const paySlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    resetSelections: (state, action) => {
      const {payload} = action;
      if (payload['selectedPay']) {
        state.selectedPay = undefined;
      }
      if (payload['selectedPayRequest']) {
        state.selectedPayRequest = undefined;
      }
      if (
        !['selectedPay', 'selectedPayRequest'].some(key =>
          Object.keys(payload).includes(key),
        )
      ) {
        state.selectedPay = undefined;
        state.selectedPayRequest = undefined;
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createPayThunk.pending, (state, action) => {})
      .addCase(createPayThunk.fulfilled, (state, action) => {
        const {payload} = action;
        prependListData(state.pays, payload);
      })
      .addCase(createPayThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
      })

      .addCase(searchPayRequestsThunk.pending, (state, action) => {})
      .addCase(searchPayRequestsThunk.fulfilled, (state, action) => {
        const {payload} = action;
        state.payRequests = payload;
      })
      .addCase(searchPayRequestsThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
        state.payRequests = undefined;
      })

      .addCase(searchPaysThunk.pending, (state, action) => {})
      .addCase(searchPaysThunk.fulfilled, (state, action) => {
        const {payload} = action;
        state.pays = payload;
      })
      .addCase(searchPaysThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
        state.pays = undefined;
      })

      .addCase(getPayByIdThunk.pending, (state, action) => {
        state.selectedPay = undefined;
      })
      .addCase(getPayByIdThunk.fulfilled, (state, action) => {
        const {payload} = action;
        state.selectedPay = payload;
      })
      .addCase(getPayByIdThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
      })

      .addCase(getPayRequestByOrderIdThunk.pending, (state, action) => {
        state.selectedPayRequest = undefined;
      })
      .addCase(getPayRequestByOrderIdThunk.fulfilled, (state, action) => {
        const {payload} = action;
        state.selectedPayRequest = payload;
      })
      .addCase(getPayRequestByOrderIdThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
      })

      // PUT

      .addCase(putPayByIdThunk.pending, (state, action) => {})
      .addCase(putPayByIdThunk.fulfilled, (state, action) => {
        const {payload} = action;
        updateSelectedData(state.selectedPay, payload);
        putListData(state.pays, payload);
      })
      .addCase(putPayByIdThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
      })

      .addMatcher(isFulfilled, (state, action) => {
        state.status = FETCH_STATUS.IDLE;
      })
      .addMatcher(isPending, (state, action) => {
        state.status = FETCH_STATUS.LOADING;
      })
      .addMatcher(isRejected, (state, action) => {
        state.status = FETCH_STATUS.FAILED;
      });
  },
});

export const {resetSelections} = paySlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getPayLoadingStatus = (state: RootState) => state.pay.status;
export const getPayRequests = (state: RootState) => state.pay.payRequests;
export const getPays = (state: RootState) => state.pay.pays;
export const getSelectedPay = (state: RootState) => state.pay.selectedPay;
export const getSelectedPayRequest = (state: RootState) =>
  state.pay.selectedPayRequest;

export default paySlice.reducer;
