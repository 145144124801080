import {ApiMethod} from '../axios/axios';

// POST METHODS
export const createAccessoryApi = {
  url: '/accessories',
  method: ApiMethod.POST,
};

// GET METHODS
export const getAccessoryByIdApi = {
  url: '/accessories',
  method: ApiMethod.GET,
};

export const searchAccessoriesApi = {
  url: '/accessories/search',
  method: ApiMethod.GET,
};

// PUT METHODS
export const putAccessoryByIdApi = {
  url: '/accessories',
  method: ApiMethod.PUT,
};

// DELETE METHODS
export const deleteAccessoryByIdApi = {
  url: '/accessories',
  method: ApiMethod.DELETE,
};
