import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from '@reduxjs/toolkit';
import {isArray} from 'lodash';
import {toast} from 'react-toastify';

import {FETCH_STATUS} from '../../constants/app';
import {ReviewType} from '../../enums';
import type {RootState} from '../../store';
import {ListPayload} from '../../types';
import {
  deleteDataFromList,
  prependListData,
  putListData,
  updateSelectedData,
} from '../../utils/apiHelper';
import {
  getReviewByIdThunk,
  putReviewThunk,
  searchReviewsThunk,
} from '../thunks';

// Define a type for the slice state

export interface ReviewPayload {
  id: string;
}

export interface ReportPayload {
  id: string;
}

interface ReviewState {
  reviews?: ListPayload<ReviewPayload>;
  reports?: ListPayload<ReportPayload>;
  selectedReview?: ReviewPayload;
  selectedReport?: ReportPayload;
  status: FETCH_STATUS;
  errorMsg?: string;
}

const initialState: ReviewState = {
  reviews: undefined,
  selectedReview: undefined,
  status: FETCH_STATUS.IDLE,
  errorMsg: undefined,
};

export const reviewSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    resetSelections: (state, action) => {
      state.selectedReport = undefined;
      state.selectedReview = undefined;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(searchReviewsThunk.pending, (state, action) => {})
      .addCase(searchReviewsThunk.fulfilled, (state, action) => {
        const {payload, meta} = action;

        const type = meta?.arg?.query?.type;
        switch (type) {
          case ReviewType.ORDER_REPORT:
            state.reports = payload;
            break;
          case ReviewType.WORKER_REVIEW:
            state.reviews = payload;
            break;
        }
      })
      .addCase(searchReviewsThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
        state.reviews = undefined;
        state.reports = undefined;
      })

      .addCase(getReviewByIdThunk.pending, (state, action) => {})
      .addCase(getReviewByIdThunk.fulfilled, (state, action) => {
        const {payload} = action;
        const type = payload?.type;
        switch (type) {
          case ReviewType.ORDER_REPORT:
            state.selectedReport = payload;
            putListData(state.reports, payload);
            break;
          case ReviewType.WORKER_REVIEW:
            state.selectedReview = payload;
            putListData(state.reviews, payload);
            break;
        }
      })
      .addCase(getReviewByIdThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
      })

      .addCase(putReviewThunk.pending, (state, action) => {})
      .addCase(putReviewThunk.fulfilled, (state, action) => {
        const {payload} = action;
        const type = payload?.type;
        switch (type) {
          case ReviewType.ORDER_REPORT:
            updateSelectedData(state.selectedReport, payload);
            putListData(state.reports, payload);
            break;
          case ReviewType.WORKER_REVIEW:
            updateSelectedData(state.selectedReview, payload);
            putListData(state.reviews, payload);
            break;
        }
      })
      .addCase(putReviewThunk.rejected, (state, action) => {
        const {payload} = action;
        console.log('payload', payload);
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
      })

      .addMatcher(isFulfilled, (state, action) => {
        state.status = FETCH_STATUS.IDLE;
      })
      .addMatcher(isPending, (state, action) => {
        state.status = FETCH_STATUS.LOADING;
      })
      .addMatcher(isRejected, (state, action) => {
        state.status = FETCH_STATUS.FAILED;
      });
  },
});

export const {resetSelections} = reviewSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getReviewLoadingStatus = (state: RootState) => state.review.status;
export const getReviews = (state: RootState) => state.review.reviews;
export const getReports = (state: RootState) => state.review.reports;
export const getSelectedReview = (state: RootState) =>
  state.review.selectedReview;
export const getSelectedReport = (state: RootState) =>
  state.review.selectedReport;

export default reviewSlice.reducer;
