export enum ReviewType {
  WORKER_REVIEW = 'WORKER_REVIEW',
  ORDER_REPORT = 'ORDER_REPORT',
}

export enum ReviewStatus {
  UNREAD = 'UNREAD',
  READ = 'READ',
  PROCESSING = 'PROCESSING',
}
