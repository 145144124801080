import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from '@reduxjs/toolkit';
import {isArray} from 'lodash';
import {toast} from 'react-toastify';

import {FETCH_STATUS} from '../../constants/app';
import type {RootState} from '../../store';
import {getAppConfigThunk, putAppConfigThunk} from '../thunks';

// Define a type for the slice state

export interface AppConfigPayload {
  id: string;
  main_slogan: string;
  minor_slogan: string;
  client_question_answer: string;
  worker_question_answer: string;
  client_about_us: string;
  worker_about_us: string;
}

interface ServiceState {
  config?: AppConfigPayload;
  status: FETCH_STATUS;
  errorMsg?: string;
}

const initialState: ServiceState = {
  config: undefined,
  status: FETCH_STATUS.IDLE,
  errorMsg: undefined,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder

      .addCase(getAppConfigThunk.pending, (state, action) => {})
      .addCase(getAppConfigThunk.fulfilled, (state, action) => {
        const {payload} = action;
        state.config = payload;
      })
      .addCase(getAppConfigThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
      })

      .addCase(putAppConfigThunk.pending, (state, action) => {})
      .addCase(putAppConfigThunk.fulfilled, (state, action) => {
        const {payload} = action;
        state.config = payload;
      })
      .addCase(putAppConfigThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
      })

      .addMatcher(isFulfilled, (state, action) => {
        state.status = FETCH_STATUS.IDLE;
      })
      .addMatcher(isPending, (state, action) => {
        state.status = FETCH_STATUS.LOADING;
      })
      .addMatcher(isRejected, (state, action) => {
        state.status = FETCH_STATUS.FAILED;
      });
  },
});

// Other code such as selectors can use the imported `RootState` type
export const getAppConfigLoadingStatus = (state: RootState) => state.app.status;
export const getAppConfig = (state: RootState) => state.app.config;
export const getMainSlogan = (state: RootState) =>
  state.app.config?.main_slogan;
export const getMinorSlogan = (state: RootState) =>
  state.app.config?.minor_slogan;
export const getClientQA = (state: RootState) =>
  state.app.config?.client_question_answer;
export const getWorkerQA = (state: RootState) =>
  state.app.config?.worker_question_answer;
export const getClientAboutUs = (state: RootState) =>
  state.app.config?.client_about_us;
export const getWorkerAboutUs = (state: RootState) =>
  state.app.config?.worker_about_us;

export default appSlice.reducer;
