import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from '@reduxjs/toolkit';
import {isArray} from 'lodash';
import {toast} from 'react-toastify';

import {FETCH_STATUS} from '../../constants/app';
import type {RootState} from '../../store';
import {ListPayload} from '../../types';
import {
  deleteDataFromList,
  prependListData,
  putListData,
  updateSelectedData,
} from '../../utils/apiHelper';
import {
  createAccessoryThunk,
  deleteAccessoryByIdThunk,
  getAccessoryByIdThunk,
  putAccessoryByIdThunk,
  searchAccessoriesThunk,
} from '../thunks';

// Define a type for the slice state

export interface AccessoryPayload {
  id: string;
  type: string;
  name: string;
}

interface ServiceState {
  accessories?: ListPayload<AccessoryPayload>;
  selectedAccessory?: AccessoryPayload;
  status: FETCH_STATUS;
  errorMsg?: string;
}

const initialState: ServiceState = {
  accessories: undefined,
  selectedAccessory: undefined,
  status: FETCH_STATUS.IDLE,
  errorMsg: undefined,
};

export const accessorySlice = createSlice({
  name: 'accessory',
  initialState,
  reducers: {
    resetSelections: (state, action) => {
      state.selectedAccessory = undefined;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createAccessoryThunk.pending, (state, action) => {})
      .addCase(createAccessoryThunk.fulfilled, (state, action) => {
        const {payload} = action;
        prependListData(state.accessories, payload);
      })
      .addCase(createAccessoryThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
      })

      .addCase(searchAccessoriesThunk.pending, (state, action) => {})
      .addCase(searchAccessoriesThunk.fulfilled, (state, action) => {
        const {payload} = action;
        state.accessories = payload;
      })
      .addCase(searchAccessoriesThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
        state.accessories = undefined;
      })

      .addCase(getAccessoryByIdThunk.pending, (state, action) => {})
      .addCase(getAccessoryByIdThunk.fulfilled, (state, action) => {
        const {payload} = action;
        state.selectedAccessory = payload;
      })
      .addCase(getAccessoryByIdThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
      })

      .addCase(putAccessoryByIdThunk.pending, (state, action) => {})
      .addCase(putAccessoryByIdThunk.fulfilled, (state, action) => {
        const {payload} = action;
        updateSelectedData(state.selectedAccessory, payload);
        putListData(state.accessories, payload);
      })
      .addCase(putAccessoryByIdThunk.rejected, (state, action) => {
        const {payload} = action;
        console.log('payload', payload);
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
      })

      .addCase(deleteAccessoryByIdThunk.pending, (state, action) => {})
      .addCase(deleteAccessoryByIdThunk.fulfilled, (state, action) => {
        const {payload, meta} = action;
        // console.log('payload', payload);

        deleteDataFromList(state.accessories, payload?.id);

        // if (payload?.deletedCount === 1) {
        //   deleteDataFromList(state.accessories, meta?.arg?.param);
        // }
      })
      .addCase(deleteAccessoryByIdThunk.rejected, (state, action) => {
        const {payload} = action;
        // console.log('payload', payload);
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
      })

      .addMatcher(isFulfilled, (state, action) => {
        state.status = FETCH_STATUS.IDLE;
      })
      .addMatcher(isPending, (state, action) => {
        state.status = FETCH_STATUS.LOADING;
      })
      .addMatcher(isRejected, (state, action) => {
        state.status = FETCH_STATUS.FAILED;
      });
  },
});

export const {resetSelections} = accessorySlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getAccessoryLoadingStatus = (state: RootState) =>
  state.accessory.status;
export const getAccessories = (state: RootState) => state.accessory.accessories;
export const getSelectedAccessory = (state: RootState) =>
  state.accessory.selectedAccessory;

export default accessorySlice.reducer;
