import {createAsyncThunk} from '@reduxjs/toolkit';

import {createEquipmentApi, searchEquipmentsApi} from '../api';
import {axiosClient} from '../axios';
import {UseRequestPayload} from '../axios/axios';

// POST
export const createEquipmentThunk = createAsyncThunk(
  'equipment/createEquipment',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      console.log('payload', payload);
      const response = await axiosClient.use(createEquipmentApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

// GET
export const searchEquipmentsThunk = createAsyncThunk(
  'equipment/searchEquipments',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(searchEquipmentsApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);
