import {LinearProgress} from '@mui/material';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import navbarConfig from './configs/mainNavbarConfig';
import {ACCESS_TOKEN_KEY} from './constants';
// LAYOUTS
import DashboardLayout from './features/dashboard/layouts/DashboardLayout';
import {useAppDispatch, useAppSelector} from './hooks/store';
import {useAuth} from './hooks/useAuth';
import {useCurrentUser} from './hooks/useCurrentUser';
import Main from './pages/Main';
import {applyToken} from './services/reducers/authSlice';
import {decryptToken} from './utils';

// PAGES
// Auth
const AuthPage = React.lazy(() => import('./features/auth/AuthPage'));
// App
const AppConfigPage = React.lazy(() => import('./pages/App/Config'));
const ClientQAPage = React.lazy(
  () => import('./pages/App/ClientQuestionAnswer'),
);
const WorkerQAPage = React.lazy(
  () => import('./pages/App/WorkerQuestionAnswer'),
);
const AboutUsPage = React.lazy(() => import('./pages/App/AboutUs'));
const PostPage = React.lazy(() => import('./pages/App/Post'));
// Categories
const CategoriesServicesPage = React.lazy(
  () => import('./pages/Categories/Services'),
);
const CategoriesAccessoriesPage = React.lazy(
  () => import('./pages/Categories/Accessories'),
);
const CategoriesEquipmentsPage = React.lazy(
  () => import('./pages/Categories/Equipments'),
);
// Pays
const PaysPage = React.lazy(() => import('./pages/Pays'));
const PayDetailsPage = React.lazy(() => import('./pages/Pays/Details'));
// Orders
const OrdersDetailsPage = React.lazy(() => import('./pages/Orders/Details'));
const OrdersSchedulesPage = React.lazy(
  () => import('./pages/Orders/Schedules'),
);
const OrdersPaymentsPage = React.lazy(() => import('./pages/Orders/Payments'));
const OrdersReviewsPage = React.lazy(() => import('./pages/Orders/Reviews'));
const OrdersReportsPage = React.lazy(() => import('./pages/Orders/Reports'));
// Members
const MembersClientsPage = React.lazy(() => import('./pages/Members/Clients'));
const MembersWorkersPage = React.lazy(() => import('./pages/Members/Workers'));
// Highlights
const HighlightPromotionPage = React.lazy(
  () => import('./pages/Highlight/Promotion'),
);
const HighlightConstructionPage = React.lazy(
  () => import('./pages/Highlight/Construction'),
);
const HighlightEquipmentsPage = React.lazy(
  () => import('./pages/Highlight/Equipments'),
);
const HighlightAccessoriesPage = React.lazy(
  () => import('./pages/Highlight/Accessories'),
);
// Analytics
const AnalyticsPage = React.lazy(() => import('./pages/Analytics'));
const AnalyticsOrdersPage = React.lazy(
  () => import('./pages/Analytics/Orders'),
);
const AnalyticsWorkersPage = React.lazy(
  () => import('./pages/Analytics/Workers'),
);
const AnalyticsClientsPage = React.lazy(
  () => import('./pages/Analytics/Clients'),
);
const ConsoleAdminsPage = React.lazy(() => import('./pages/Console/Users'));

const ProtectedContent = ({children}: {children: React.ReactNode}) => {
  const isAuth = useAuth();
  if (isAuth) {
    return <>{children}</>;
  } else {
    return <Navigate to="/auth" replace />;
  }
};

const AuthContent = ({children}: any) => {
  const isAuth = useAuth();

  if (isAuth) {
    return <Navigate to="/" replace />;
  } else {
    return children;
  }
};
const Router = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAuth = useAuth();
  const user = useCurrentUser();
  const [isLoading, setIsLoading] = useState(true);

  const navConfig = useMemo(() => {
    const userRight = user?.right;
    return navbarConfig?.filter(obj =>
      obj.rights?.some((right: any) => userRight === right),
    );
  }, [user]);

  const allowedPaths = useMemo(() => {
    return navConfig
      .map(obj => obj.payload)
      .flat()
      .map(obj => obj.path);
  }, [navConfig]);

  const init = useCallback(async () => {
    const encryptedToken = window.localStorage.getItem(ACCESS_TOKEN_KEY);
    const token = decryptToken(
      encryptedToken || '',
      process.env.REACT_ENCRYPT_TOKEN_SECRET || '',
    );
    dispatch(applyToken(token));
    setIsLoading(false);
  }, [dispatch]);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (isAuth) {
      // dispatch(getAccountInfoThunk());
      // dispatch(getJoinedRoomsThunk({}));
    }
  }, [isAuth]);

  // Nav to main if no right
  useEffect(() => {
    if (!isAuth) return;
    const {pathname} = location || {};
    console.log('pathname', pathname);
    const isOnAllowedPath = allowedPaths?.some(path =>
      pathname.startsWith(path),
    );
    // console.log('isOnAllowedPath', isOnAllowedPath);
    if (!isOnAllowedPath && pathname !== '/main') {
      navigate('/main', {replace: true});
    }
  }, [location]);

  return (
    <Routes>
      {/* <Route index element={<div>Home</div>} /> */}
      <Route
        path="auth"
        element={
          <AuthContent>
            <AuthPage />
          </AuthContent>
        }
      />
      <Route
        path="/"
        element={
          isLoading ? (
            <LinearProgress />
          ) : (
            <ProtectedContent>
              <DashboardLayout navBarConfig={navConfig} />
            </ProtectedContent>
          )
        }>
        <Route index element={<Navigate to="/main" />} />
        <Route path="">
          <Route path="main" element={<Main />} />
        </Route>
        <Route path="app">
          <Route path="config" element={<AppConfigPage />} />
          <Route path="client_question_answer" element={<ClientQAPage />} />
          <Route path="worker_question_answer" element={<WorkerQAPage />} />
          <Route path="about_us" element={<AboutUsPage />} />
          <Route path="post/*" element={<PostPage />} />
        </Route>
        <Route path="categories">
          <Route path="services/*" element={<CategoriesServicesPage />} />
          <Route path="accessories/*" element={<CategoriesAccessoriesPage />} />
          <Route path="equipments/*" element={<CategoriesEquipmentsPage />} />
          {/* <Route path=":roomId" element={<RoomLayout />}>
						<Route index element={<RoomPage />} />
					</Route> */}
        </Route>
        <Route path="pays">
          <Route index element={<PaysPage />} />
          <Route path="details/*" element={<PayDetailsPage />} />
        </Route>
        <Route path="orders">
          <Route path="details/*" element={<OrdersDetailsPage />} />
          <Route path="schedules/*" element={<OrdersSchedulesPage />} />
          <Route path="payments/*" element={<OrdersPaymentsPage />} />
          <Route path="reviews/*" element={<OrdersReviewsPage />} />
          <Route path="reports/*" element={<OrdersReportsPage />} />
        </Route>
        <Route path="members">
          <Route path="clients/*" element={<MembersClientsPage />} />
          <Route path="workers/*" element={<MembersWorkersPage />} />
        </Route>
        <Route path="highlight">
          <Route path="promotion/*" element={<HighlightPromotionPage />} />
          <Route
            path="construction/*"
            element={<HighlightConstructionPage />}
          />
          <Route path="equipments/*" element={<HighlightEquipmentsPage />} />
          <Route path="accessories/*" element={<HighlightAccessoriesPage />} />
        </Route>
        <Route path="analytics">
          <Route index element={<AnalyticsPage />} />
          {/* <Route path="orders/*" element={<AnalyticsOrdersPage />} />
          <Route path="workers/*" element={<AnalyticsWorkersPage />} />
          <Route path="clients/*" element={<AnalyticsClientsPage />} /> */}
        </Route>
        <Route path="console">
          <Route index element={<ConsoleAdminsPage />} />
          <Route path="users/*" element={<ConsoleAdminsPage />} />
          {/* <Route path="workers/*" element={<AnalyticsWorkersPage />} />
          <Route path="clients/*" element={<AnalyticsClientsPage />} /> */}
        </Route>
      </Route>
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default Router;
