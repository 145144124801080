import {PaletteType} from '@material-ui/core';
import {PaletteMode} from '@mui/material';

import {COLORS} from '../constants';
import styles from '../index.module.scss';

const commonThemePalette = {
  welcomeBackground: styles.welcomeBackground,
};

const lightTheme = {
  // palette values for light mode
  palette: {
    ...commonThemePalette,
    primary: {
      main: styles.primaryButton,
    },
    secondary: {main: COLORS.accentButton},
    divider: styles.lightBorder,
    background: {
      default: styles.lightBackground,
      paper: styles.lightPaper,
    },
    text: {
      main: styles.lightText,
      primary: styles.lightText,
      secondary: styles.lightText,
      contrast: styles.darkText,
      white: '#FFFFFF',
    },
  },
  typography: {
    allVariants: {
      color: styles.lightText,
    },
  },
};

const darkTheme = {
  // palette values for dark mode
  palette: {
    ...commonThemePalette,
    primary: {
      main: COLORS.primaryButton,
    },
    secondary: {main: COLORS.accentButton},
    divider: styles.darkBorder,
    background: {
      default: '#18181a',
      paper: styles.darkPaper,
    },
    text: {
      main: styles.darkText,
      primary: styles.darkText,
      secondary: styles.darkText,
      contrast: styles.lightText,
      white: '#FFFFFF',
    },
  },
  typography: {
    allVariants: {
      color: styles.darkText,
    },
  },
  components: {
    MuiIcon: {
      styleOverrides: {
        root: {
          color: styles.darkText,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: styles.darkText,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: styles.darkText,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: styles.darkText,
        },
      },
    },
  },
};

export const getDesignTokens = (mode: PaletteMode) => ({
  ...(mode === 'light' ? lightTheme : darkTheme),
});
