import {Box, Typography} from '@mui/material';
import React from 'react';

import {APP_NAME, IMAGES} from '../constants';
import styles from './MainLogo.module.scss';

interface IProps {
  onClick?: () => void;
  logoStyles?: React.CSSProperties | undefined;
}

function MainLogo({onClick, logoStyles, ...props}: IProps) {
  return (
    <Box
      component="div"
      onClick={onClick}
      className={styles.container}
      sx={{
        cursor: onClick ? 'pointer' : 'initial',
      }}
      {...props}>
      {/* <LogoVector
				style={{ ...styles.logoVector, ...logoStyles }}
				// draggable="false"
			/> */}
      {/* <Typography variant="h5" className={styles.logoFont}>
        {APP_NAME}
      </Typography> */}
      <img src={IMAGES.logo} className={styles.logo} />
    </Box>
  );
}

export default MainLogo;
