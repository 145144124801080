import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from '@reduxjs/toolkit';
import {isArray} from 'lodash';
import {toast} from 'react-toastify';

import {FETCH_STATUS} from '../../constants/app';
import type {RootState} from '../../store';
import {ListPayload} from '../../types';
import {
  deleteDataFromList,
  prependListData,
  putListData,
  updateSelectedData,
} from '../../utils/apiHelper';
import {
  approveManualPaymentThunk,
  getPaymentByIdThunk,
  searchPaymentsThunk,
} from '../thunks';

// Define a type for the slice state

export interface PaymentPayload {
  id: string;
}

interface PaymentState {
  payments?: ListPayload<PaymentPayload>;
  selectedPayment?: PaymentPayload;
  status: FETCH_STATUS;
  errorMsg?: string;
}

const initialState: PaymentState = {
  payments: undefined,
  selectedPayment: undefined,
  status: FETCH_STATUS.IDLE,
  errorMsg: undefined,
};

export const paymentSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    resetSelections: (state, action) => {
      state.selectedPayment = undefined;
    },
  },
  extraReducers: builder => {
    builder
      // .addCase(createServiceThunk.pending, (state, action) => {})
      // .addCase(createServiceThunk.fulfilled, (state, action) => {
      //   const {payload} = action;
      //   prependListData(state.orders, payload);
      // })
      // .addCase(createServiceThunk.rejected, (state, action) => {
      //   const {payload} = action;
      //   if (payload) {
      //     toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
      //   }
      // })

      .addCase(searchPaymentsThunk.pending, (state, action) => {})
      .addCase(searchPaymentsThunk.fulfilled, (state, action) => {
        const {payload} = action;
        state.payments = payload;
      })
      .addCase(searchPaymentsThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
        state.payments = undefined;
      })

      .addCase(getPaymentByIdThunk.pending, (state, action) => {})
      .addCase(getPaymentByIdThunk.fulfilled, (state, action) => {
        const {payload} = action;
        state.selectedPayment = payload;
      })
      .addCase(getPaymentByIdThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
      })

      .addCase(approveManualPaymentThunk.pending, (state, action) => {})
      .addCase(approveManualPaymentThunk.fulfilled, (state, action) => {
        const {payload} = action;
        updateSelectedData(state.selectedPayment, payload);
        putListData(state.payments, payload);
      })
      .addCase(approveManualPaymentThunk.rejected, (state, action) => {
        const {payload} = action;
        console.log('payload', payload);
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
      })

      // .addCase(deleteServiceByIdThunk.pending, (state, action) => {})
      // .addCase(deleteServiceByIdThunk.fulfilled, (state, action) => {
      //   const {payload, meta} = action;
      //   if (payload?.deletedCount === 1) {
      //     deleteDataFromList(state.services, meta?.arg?.param);
      //   }
      // })
      // .addCase(deleteServiceByIdThunk.rejected, (state, action) => {
      //   const {payload} = action;
      //   // console.log('payload', payload);
      //   if (payload) {
      //     toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
      //   }
      // })

      .addMatcher(isFulfilled, (state, action) => {
        state.status = FETCH_STATUS.IDLE;
      })
      .addMatcher(isPending, (state, action) => {
        state.status = FETCH_STATUS.LOADING;
      })
      .addMatcher(isRejected, (state, action) => {
        state.status = FETCH_STATUS.FAILED;
      });
  },
});

export const {resetSelections} = paymentSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getPaymentLoadingStatus = (state: RootState) =>
  state.payment.status;
export const getPayments = (state: RootState) => state.payment.payments;
export const getSelectedPayment = (state: RootState) =>
  state.payment.selectedPayment;

export default paymentSlice.reducer;
