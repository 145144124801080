import {createAsyncThunk} from '@reduxjs/toolkit';

import {getOrderByIdApi, searchOrdersApi} from '../api/orderApi';
import {axiosClient} from '../axios';
import {UseRequestPayload} from '../axios/axios';

export const searchOrdersThunk = createAsyncThunk(
  'order/searchOrders',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(searchOrdersApi, payload);
      return {...response?.data?.data, type: payload?.query?.type || ''};
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const getOrderByIdThunk = createAsyncThunk(
  'order/getOrderById',
  async (payload: UseRequestPayload = {}, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(getOrderByIdApi, payload);
      console.log('getOrderByIdThunk response', response);
      // The value we return becomes the `fulfilled` action payload
      const data = response?.data?.data;
      return data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

// PUT
