import CloseIcon from '@mui/icons-material/Close';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import MusicOffIcon from '@mui/icons-material/MusicOff';
import {Box, Drawer, IconButton, Stack} from '@mui/material';
import React, {createContext, useCallback, useContext, useState} from 'react';

import useAudioMuted from '../hooks/useAudioMuted';
import styles from './DrawerContext.module.scss';

interface ContextProps {
  toggleDrawer: () => void;
  openDrawer: () => void;
  closeDrawer: () => void;
}

export const DrawerContext = createContext<any>(undefined);
export const useDrawer = () => useContext<ContextProps>(DrawerContext);

interface IProps {
  children: React.ReactNode;
}

const DrawerProvider = ({children}: IProps) => {
  const [open, setOpen] = useState(false);
  const {muted, toggleMuted} = useAudioMuted();

  const openDrawer = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const closeDrawer = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const toggleDrawer = useCallback(() => {
    if (open) {
      closeDrawer();
    } else {
      openDrawer();
    }
  }, [open, closeDrawer, closeDrawer]);

  const values = {toggleDrawer, openDrawer, closeDrawer};

  return (
    <DrawerContext.Provider value={values}>
      {children}
      <Drawer
        anchor={'right'}
        open={open}
        onClose={() => setOpen(true)}
        PaperProps={{className: styles.drawer}}>
        <Box className={styles.header}>
          <IconButton className={styles.closeButton} onClick={closeDrawer}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Stack className={styles.content} sx={{display: 'flex', p: 5}}>
          <Box>
            通知聲音: {muted ? '靜音' : '開啟'}
            <IconButton onClick={toggleMuted}>
              {muted ? <MusicOffIcon /> : <MusicNoteIcon />}
            </IconButton>
          </Box>
        </Stack>
        <Box className={styles.footer}>
          <p>Version: {process.env.REACT_APP_VERSION}</p>
        </Box>
      </Drawer>
    </DrawerContext.Provider>
  );
};

export default DrawerProvider;
