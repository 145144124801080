import {createAsyncThunk} from '@reduxjs/toolkit';

import {
  createServiceApi,
  deleteServiceByIdApi,
  getServiceByIdApi,
  putServiceApi,
  searchServicesApi,
} from '../api/serviceApi';
import {axiosClient} from '../axios';
import {UseRequestPayload} from '../axios/axios';

// POST
export const createServiceThunk = createAsyncThunk(
  'service/createService',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      console.log('payload', payload);
      const response = await axiosClient.use(createServiceApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

// GET
export const getServiceByIdThunk = createAsyncThunk(
  'service/getServiceById',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(getServiceByIdApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const searchServicesThunk = createAsyncThunk(
  'service/searchServices',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(searchServicesApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

// PUT
export const putServiceThunk = createAsyncThunk(
  'service/putService',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(putServiceApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

// DELETE
export const deleteServiceByIdThunk = createAsyncThunk(
  'service/deleteServiceById',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(deleteServiceByIdApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);
