import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';

import {Overlays} from '../components/Overlays';
import {
  DialogStateProps,
  OverlaysDialogHandleProps,
} from '../components/Overlays/DialogModel';

interface ContextProps {
  open: () => void;
  close: () => void;
  reset: (props?: DialogStateProps) => void;
}

export const DialogContext = createContext<any>(undefined);
export const useDialog = () => useContext<ContextProps>(DialogContext);

interface IProps {
  children: React.ReactNode;
}

const DialogProvider = ({children}: IProps) => {
  const dialogRef = useRef<OverlaysDialogHandleProps>(null);

  const open = useCallback(() => {
    dialogRef.current?.open();
  }, [dialogRef.current]);

  const close = useCallback(() => {
    dialogRef.current?.close();
  }, [dialogRef.current]);

  const reset = useCallback(
    (props: DialogStateProps) => {
      dialogRef?.current?.reset(props);
    },
    [dialogRef?.current],
  );

  const values = useMemo(
    () => ({
      open,
      close,
      reset,
    }),
    [open, close, reset],
  );

  return (
    <DialogContext.Provider value={values}>
      {children}
      <Overlays.Dialog ref={dialogRef} />
    </DialogContext.Provider>
  );
};

export default DialogProvider;
