import {ApiMethod} from '../axios/axios';

export const googleLoginApi = {
  url: 'v1/auth/login/google',
  method: ApiMethod.POST,
};

export const usernameLoginApi = {
  url: 'auth/admins/login/username',
  method: ApiMethod.POST,
};
export const loginApi = {
  url: '/v1/auth/login/email',
  method: ApiMethod.POST,
};

export const registerApi = {
  url: '/v1/auth/register',
  method: ApiMethod.POST,
};

export const getAccountInfoApi = {
  url: '/v1/auth/account',
  method: ApiMethod.GET,
};

export const putAccountInfoApi = {
  url: '/v1/auth/account',
  method: ApiMethod.PUT,
};
