import {ApiMethod} from '../axios/axios';

export const createPayApi = {
  url: 'pays/create',
  method: ApiMethod.POST,
};

export const searchPaysApi = {
  url: 'pays/search',
  method: ApiMethod.GET,
};

export const searchPayRequestsApi = {
  url: 'pays/requests/search',
  method: ApiMethod.GET,
};

export const getPayByIdApi = {
  url: 'pays',
  method: ApiMethod.GET,
};

export const getPayRequestByOrderIdApi = {
  url: 'pays/requests',
  method: ApiMethod.GET,
};

export const putPayByIdApi = {
  url: 'pays',
  method: ApiMethod.PUT,
};
