export enum MembershipStatus {
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
  DISAPPROVED = 'DISAPPROVED',
  REVOKED = 'REVOKED',
}

export enum MemberAccountType {
  CLIENT = 'CLIENT',
  WORKER = 'WORKER',
}
