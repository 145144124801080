import {createAsyncThunk} from '@reduxjs/toolkit';

import {getReviewByIdApi, putReviewApi, searchReviewsApi} from '../api';
import {
  approveManualPaymentApi,
  getPaymentByIdApi,
  searchPaymentsApi,
} from '../api/paymentApi';
import {axiosClient} from '../axios';
import {UseRequestPayload} from '../axios/axios';

// GET
export const searchReviewsThunk = createAsyncThunk(
  'review/searchReviews',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(searchReviewsApi, payload);
      console.log('searchReviewsThunk response', response);
      return {...response?.data?.data, type: payload?.query?.type || ''};
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const getReviewByIdThunk = createAsyncThunk(
  'review/getReviewById',
  async (payload: UseRequestPayload = {}, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(getReviewByIdApi, payload);
      console.log('getReviewByIdThunk response', response);
      // The value we return becomes the `fulfilled` action payload
      const data = response?.data?.data;
      return data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

// PUT
export const putReviewThunk = createAsyncThunk(
  'payment/putReview',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(putReviewApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);
